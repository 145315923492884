<template>

  <baselineLayout>
  <link rel="stylesheet" href="/css/extra.css">
  <link rel="stylesheet" href="/css/SliderAnimation.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
    <baselineBreadCrumbs/>
    <div class="p-4 2xl:ml-72 xl:ml-72 lg:ml-72 sm:ml-0 mr-4 h-full p-4 mt-2 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-100">

      <div class="flex flex-col items-center w-full mx-auto px-4 lg:px-6 sm:px-1 sm:py-6 justify-center">
        <!-- Table -->
        <div class="flex w-full justify-center bg-white shadow-md rounded-t-sm border-t border-b">
          <div class="flex justify-center">
            <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
                <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
                    <li class="me-2" role="presentation">
                        <button class="inline-block p-4 border-b-2 rounded-t-lg" id="paper-trading-tab" data-tabs-target="#paper-trading" type="button" role="tab" aria-controls="paper-trading" aria-selected="false">Paper Trading</button>
                    </li>
                    <li class="me-2" role="presentation">
                        <button class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" id="live-trading-tab" data-tabs-target="#live-trading" type="button" role="tab" aria-controls="live-trading" aria-selected="false">Live Trading</button>
                    </li>
                </ul>
            </div>
          </div>
        </div>
        <div class="w-full mx-auto bg-white shadow-md rounded-b-sm border-t-sm border-gray-200">
          

          <div id="default-tab-content">
              <div class="hidden flex flex-col p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="paper-trading" role="tabpanel" aria-labelledby="paper-trading-tab">
                <!-- Snippet -->
                <section class="flex flex-col justify-center antialiased text-gray-600">
                    <div class="w-full mx-auto pb-4 h-full">
                        <analyticsChart
                        :propData="this.traderDashboardStore.http_portfolio_agg" />
                    </div>
                </section>

                <div class="flex flex-col bg-white shadow-md">
                  <header class="flex justify-center items-center justify-between flex-row px-5 py-4 border-b border-gray-100">
                      <h2 class="font-semibold text-gray-800 text-xl">Your Strategies 🚀</h2>
                      <a href="/product/portfolio_operation" class="flex items-center text-white bg-green-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"><span class="material-symbols-outlined mr-2">bento</span>Create Strategy</a>
                  </header>

                  <div class="p-3">
                    <div class="overflow-x-auto">
                        <table class="table-auto w-full">
                            <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                <tr>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-left">Name</div>
                                    </th>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-left">Creator</div>
                                    </th>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-left">Cumulative Return %</div>
                                    </th>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-left">Cumulative Value</div>
                                    </th>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-left">Win/Ratio</div>
                                    </th>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-center">Explore</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="Object.keys(traderDashboardStore.http_portfolio_agg.portfolio).length > 0" class="text-sm divide-y divide-gray-100">
                                <tr @click="handlePortfolioRowClick(portfolio_sing.portfolio_meta.id)" v-for="portfolio_sing in this.sortPortfolios(Object.values(traderDashboardStore.http_portfolio_agg.portfolio))" v-bind:key="portfolio_sing.portfolio_meta.id">
                                    <td class="p-2">
                                        <div class="flex items-center">
                                            <div class="flex mr-1 2xl:flex-row xl:flex-row md:flex-row sm:flex-col">
                                              <SparklineComponent v-bind:data="this.shrinkArray(traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]['cumulative_return_timeline'], 30)" />
                                              <p class="text-xs	italic 2xl:hidden xl:hidden md:hidden sm:visible">Click to view</p>
                                              <!-- <SparklineComponent v-bind:data="[2, 3, 5, 7, -11, 13, 17, 19, 23, 29, 31]" /> -->
                                              </div>
                                            <div class="font-medium text-gray-800 w-64">{{traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.portfolio_meta?.name}}
                                            <span v-if="traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.portfolio_meta?.is_archived" class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">🏷️ Archived</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="p-2 whitespace-nowrap">
                                        <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">{{traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.user.is_owner ? 'You ⭐': 'Community'}}</span>
                                    </td>
                                    <td class="p-2 whitespace-nowrap">
                                        <div :class="traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.cumulative_return_pct > 0 ? 'text-center font-medium text-gray text-green-500': 'text-center font-medium text-gray text-red-500'">{{formatter.format(traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.cumulative_return_pct)}}%</div>
                                    </td>
                                    <td class="p-2 whitespace-nowrap">
                                        <div class="text-center font-medium">${{parseFloat(traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.cumulative_value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}}</div>
                                    </td>
                                    <td class="p-2 whitespace-nowrap">
                                        <div :class="traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.trades_analysis?.winrate > 60 ? 'text-center font-medium text-gray text-green-500': 'text-center font-medium text-gray text-red-500'">{{traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.trades_analysis?.winrate !== null ? (parseFloat(traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.trades_analysis?.winrate).toFixed(2).toString() + ("%")).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "0%"}}</div>
                                    </td>
                                    <td class="p-2 whitespace-nowrap">
                                        <div class="inline-flex rounded-md shadow-sm" role="group">
                                        <a v-bind:href=' "/product/bot_instance?portfolio_id=" + traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.portfolio_meta?.id' type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                          <span class="material-symbols-outlined mr-1">explore</span>
                                          Explore
                                        </a>
                                        <a v-bind:href=' "/product/portfolio_operation?operation_environment=portfolio_modify&portfolio_id=" + traderDashboardStore.http_portfolio_agg.portfolio[portfolio_sing.portfolio_meta.id]?.portfolio_meta?.id' type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                          <span class="material-symbols-outlined mr-1">edit</span>
                                          Edit
                                        </a>
                                      </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <div v-if="Object.keys(traderDashboardStore.http_portfolio_agg.portfolio).length <= 0" class="flex flex-col w-full overflow-x-auto items-center py-4">
                              <semipolar-spinner
                                :animation-duration="2000"
                                :size="120"
                                color="#0051d8"
                              />
                              <progressBar class="flex mt-8" />
                          </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hidden flex flex-col p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="live-trading" role="tabpanel" aria-labelledby="live-trading-tab">
                <div class="flex w-full justify-center">
                  <div class="flex flex-col items-center w-4/5 p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                      <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white ">Join Waitlist 👋</h5>
                      <p class="w-1/2 mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">We're currently releasing this feature to a select group of individuals, send us a message in chat to join the list!</p>
                      <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
                          <button @click="this.handleJoinWaitlist" class="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-white transition duration-300 ease-out border-2 border-[#008FFB] rounded-full shadow-md group">
<span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#008FFB] group-hover:translate-x-0 ease">
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute flex items-center justify-center w-full h-full text-gray-500 transition-all duration-300 transform group-hover:translate-x-full ease">Join Waitlist</span>
<span class="relative invisible">Button Text</span>
                          </button>
                      </div>
                  </div>
                </div>
              </div>
          </div>

        </div>
      </div>
    </div>
  </baselineLayout>
</template>

<script>
import baselineLayout from './components/baselineLayout.vue'
import { SemipolarSpinner  } from 'epic-spinners'
import SparklineComponent from './components/SparklineComponent.vue'
import baselineBreadCrumbs from './components/baselineBreadCrumbs.vue'
import progressBar from './components/progressBar.vue'
import analyticsChart from './components/analyticsChart.vue'
import { traderDashboardStore } from './stores/PageBotTraderDashboardStore'

const formatter = new Intl.NumberFormat("en-GB", { style: "decimal",  signDisplay: 'always' });

export default {
  name: 'PageBotTraderDashboard',
  components: {
    baselineLayout,
    baselineBreadCrumbs,
    SparklineComponent,
    SemipolarSpinner,
    progressBar,
    analyticsChart
  },
  data () {
      return {traderDashboardStore, formatter}
  },
  mounted(){
      this.refreshToken();
      this.getUser();
      this.getModelFolios();
      setInterval(() => {
        console.log("Auto JWT refresh");
        this.refreshToken();
      }, 60000);
  },
  methods: {
        async refreshToken(){
          var formdata = new FormData();
          formdata.append("refresh", localStorage.getItem("barpot_jwt_refresh"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/refresh/`, requestOptions)
            .then(response => response.json())
            .then(result => {
              localStorage.setItem("barpot_jwt_access", result.access)
            })
            .catch(error => console.log('error', error));
        },
        async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              return response.json()
              }
            )
            .then(data => {
              traderDashboardStore.userData = data
              localStorage.setItem("username", data["info"]["username"])
            })
            .catch(error => console.log('error', error));
        },
        handleSignout(){
          localStorage.removeItem("barpot_jwt_access")
          localStorage.removeItem("barpot_jwt_refresh")
          window.location = "/login"
        },
        handleAccount(){
          window.location = "/account"
        },
        handleHome(){
          window.location = "/dashboard"
        },
        sortPortfolios(portfolios){
          return portfolios.sort((a, b) => {
            return a.portfolio_meta.is_archived - b.portfolio_meta.is_archived
          })
        },
        promptForSignInKey() {
            var tier_key = prompt('Please enter your Tier Key (This is acquired only when you purchase a "paid tier" plan.)', "");
            if (tier_key != null) {
                localStorage.setItem("auth_tier_key", tier_key)
            }
        },
        async populateGridAsOverviewAsNonPremiumMember(){
            let url = new URL(window.location.href);
            let c_page = url.searchParams.get("page_num")
            await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream_overview?page_num=${c_page}`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                this.xhrDataCardData = {
                  ...this.xhrDataCardData,
                  ...data,
                }
            });
        },
        async toggleRiskClick(){
          this.populateGrid()
        },
        async populateGrid(){
            let url = new URL(window.location.href);
            let c_page = url.searchParams.get("page_num");
            let risk_types = [];
            if(document.getElementById("toggle_low").checked){
              risk_types.push("risk_low")
            }
            if(document.getElementById("toggle_high").checked){
              risk_types.push("risk_high")
            }
            if(document.getElementById("toggle_moderate").checked){
              risk_types.push("risk_moderate")
            }
            risk_types = risk_types.join(",")
            if (risk_types.length <= 0){
              risk_types = ["risk_low"]
            }
            await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream?page_num=${c_page}&risk_types=${risk_types}`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                this.populateGridAsOverviewAsNonPremiumMember()
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                this.xhrDataCardData = {
                  ...this.xhrDataCardData,
                  ...data,
                }
            });
        },
        onStockSubmit(event){
          if (event.keyCode === 13){
            window.location.href = `/stock?symbol=${event.target.value}`
          }
          
        },
        handlePreviousPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0){
            c_page = 0;
          }
          url.searchParams.set('page_num', (c_page - 1));
          window.location.href = url.href;
        },
        handleNextPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0 || isNaN(c_page) == true){
            c_page = 0;
          }
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.next_page);
          window.location.href = url.href;
        },
        handleBeginPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', 0);
          window.location.href = url.href;
        },
        handleLastPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.page_total);
          window.location.href = url.href;
        },
        normalizeTime(){
            console.log(this.users)
        },
        generateRandomNumber(min, max) {
            let highlightedNumber = Math.random() * (max - min) + min;
            return(highlightedNumber);
        },
        shrinkArray(array, size) {
          const step = array.length / size
          return array.filter((v, i) => Math.floor(i % step) == 0)
        },
        paramVis(data){
    let _m = {}
    Object.keys(data).map(item => {
      if (traderDashboardStore.parameterVisConfig[item]?.display == true){
        _m[item] = data[item]
      }
    })
    return _m
  },
        async getModelFolios(){
            var requestOptions = {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              }
            };
              await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/portfolio_trader/get_all_portfolios/`, requestOptions)
              .then(response => response.json())
              .then(data => {
                console.warn("HHHHH", data)
                traderDashboardStore.http_portfolio_agg = data
              })
              .catch(error => console.log('error', error));
  },
  handlePortfolioRowClick(portfolio_id){
    window.location.href = '/product/bot_instance?portfolio_id=' + traderDashboardStore.http_portfolio_agg.portfolio[portfolio_id]?.portfolio_meta?.id
  },
  handleJoinWaitlist(){
    window.tidioChatApi.open();
  }
    }
}

</script>

<style>
.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background-color: #ffffff;
  width: 95%;
  height: 35vw;
  padding: 16px 6px 16px 16px;
}
</style>