import { reactive } from 'vue'

export const traderInstanceStore = reactive({
    xhrDataCardDataPopular: [
      ],
    xhrDataCardDataETF: [
      ],
    xhrDataCardData: {
      signal_stream: [],
      pagination: {
        curr_page: "",
        page_total: ""
      }
    },
    xhrDataPortfolioItem: {},
    risk_indicators: {
        low: true,
        high: true,
        moderate: true
    },
    userData: {
      subscription_status: "",
      username: "...",
      email: "...",
      info: {
        phone_number: ""
      }
    },
})