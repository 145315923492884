<template>
    <div class="flex">
        <button id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" data-dropdown-placement="bottom" class="text-white bg-blue-700 max-w-xs hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
            {{store.isAutoSelectStocks == true? "Automatic Symbol Selection" :
                (Object.keys(store.selectSymbol).length == 0 ? "Select Symbols": Object.keys(store.selectSymbol).join(', '))}}
            <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg></button>
              <!-- Dropdown menu -->
              <div id="dropdownSearch" class="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
                  <div class="p-3">
                    <label for="input-group-search" class="sr-only">Search Symbol</label>
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                      </div>
                      <input @keyup="onPressSymbolSearch($event)" :disabled="store.isAutoSelectStocks" placeholder="Enter Your Symbol" type="text" id="input-group-search" class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none">
                    </div>
                  </div>
                  <ul class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
                    <li v-for="(item, index) in getAllItem(store.xhrDataStockSearch?.data)" :key="item.symbol + index">
                      <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <input :id="'checkbox-item-' + index" @change="addSymbolAsSelected($event, item.symbol)" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label :for="'checkbox-item-' + index" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{item.symbol}}</label>
                      </div>
                    </li>
                    <div v-if="store.xhrDataStockSearch.data.length > 0" class="flex flex-col items-center justify-center mt-2">
                        <hr class="w-full h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                    </div>
                    <div v-if="store.xhrDataStockSearch.data.length <= 0 && Object.keys(store.selectSymbol).length == 0" class="flex flex-col items-center justify-center mt-2">
                        <hr class="w-full h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
                        <span class="material-symbols-outlined mt-2" style=" font-size: 5em; ">find_in_page</span>
                        <h1 class="font-semibold text-center text-xl text-gray-900 underline dark:text-white decoration-green-500">Find Stocks/Securities</h1>
                    </div>
                    <li v-for="existingItem in Object.keys(store.selectSymbol)" :key="existingItem.symbol + 'user-selected' + store.selectSymbol[existingItem].symbol">
                      <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <input :id="'user-selected-checkbox-item-' + store.selectSymbol[existingItem].symbol" @change="handleUserSelectedSymbol(existingItem)" type="checkbox" :checked="store.selectSymbol[existingItem].checked" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label :for="'user-selected-checkbox-item-' + store.selectSymbol[existingItem].symbol" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{store.selectSymbol[existingItem].symbol}}</label>
                      </div>
                    </li>
                  </ul>
                  
                  <div class="flex items-center p-3 text-sm font-medium text-green-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-green-500 hover:underline">
                      <div class="flex items-center h-5">
                          <input id="ai-picks-checkbox" :checked="store.isAutoSelectStocks" @change="store.isAutoSelectStocks = $event.target.checked" aria-describedby="ai-picks-checkbox-text" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      </div>
                      <div class="ml-2 text-sm">
                          <div class="flex flex-row items-center space-x-2">
                            <label for="ai-picks-checkbox" class="font-medium text-gray-900 dark:text-gray-300">Smart Picks </label>
                            <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1v3m5-3v3m5-3v3M1 7h7m1.506 3.429 2.065 2.065M19 7h-2M2 3h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm6 13H6v-2l5.227-5.292a1.46 1.46 0 0 1 2.065 2.065L8 16Z"></path>
                            </svg>
                          </div>
                          <p id="ai-picks-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">AI curated stock picks automatically selected based on your portfolio configuration</p>
                      </div>
                  </div>
              </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

const store = reactive({
    xhrDataStockSearch: {data: [], timestamp: null},

    // {"symbol": {"checked": true}}
    selectSymbol: {
        // "AAPL": {"checked": true, "symbol": "AAPL"}
    },
    isAutoSelectStocks: false,
})
export default {
 props: {
  defaultData: Object,
  isAutomatic: Boolean,
 },
 components: {
  },
  watch: {
    isAutomatic: function(newVal, oldVal) { // watch it
      console.log('Prop isAutomatic changed: ', newVal, ' | was: ', oldVal)
      if(this.isAutomatic !== null){
        store.isAutoSelectStocks = this.isAutomatic
      }
    },
    defaultData: function(newVal, oldVal) { // watch it
      console.log('Prop defaultData changed: ', newVal, ' | was: ', oldVal)
      if(this.defaultData){
        store.selectSymbol = this.defaultData
      }
    }
  },
 data(){
     return {store}
 },
 methods: {
    handleUserSelectedSymbol(symbol){
        delete store.selectSymbol[symbol]
    },
    addSymbolAsSelected(event, symbol){
        if (Object.keys(store.selectSymbol).indexOf(symbol) > -1){
            console.log("EXIST", symbol)
            store.selectSymbol[symbol].checked = event.target.checked
        } else {
            console.log("Create", symbol)
            store.selectSymbol[symbol] = {"checked": true, "symbol": symbol}
        }
    },
    getAllItem(data){
        return data.filter(d => Object.keys(store.selectSymbol).indexOf(d.symbol) === -1)
    },
    async onPressSymbolSearch(event){
        console.log(store)
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };
          fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stock_search?keyword=${event.target.value}`, requestOptions)
            .then(response => response.json())
            .then(respData => {
              if (store.xhrDataStockSearch.timestamp !== null){
                if (respData.timestamp > store.xhrDataStockSearch.timestamp){
                  store.xhrDataStockSearch = respData
                }
              } else {
                store.xhrDataStockSearch = respData
              }
              return
            })
            .catch(error => console.log('error', error));
            return
        },
 }
}
</script>

<style scoped>

</style>